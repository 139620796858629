<template>
    <v-container fluid>
        <validation-observer ref="observer" v-slot="{ invalid, validated }">
            <v-toolbar flat>
                <v-toolbar-title
                    ><colored-status :value="statusActive" :tooltip="statusText" />{{
                        $t('oampublication/label--page-title')
                    }}
                    #{{ id }}</v-toolbar-title
                >
                <v-spacer />
                <v-btn color="primary" outlined class="ma-4" @click="close()">
                    {{ $t('oampublication/action--close') }}
                </v-btn>
                <v-btn color="primary" :disabled="invalid || !validated" @click="save()">
                    {{ $t('oampublication/action--save') }}
                </v-btn>
            </v-toolbar>

            <v-tabs v-model="tab">
                <v-tab key="Pms" tab-value="Pms"
                    >{{ $t('oampublication/label--tab-title-pms-places') }}
                    <sup v-if="tab !== 'Pms'" class="ml-1">New</sup></v-tab
                >
                <v-tab key="System" tab-value="System">{{ $t('oampublication/label--tab-title-system-places') }}</v-tab>
                <v-tab key="User" tab-value="User">{{ $t('oampublication/label--tab-title-user-places') }}</v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
                <v-tab-item v-if="loaded && tab === 'Pms'" key="Pms" value="Pms">
                    <JobAdPubPlacesPmsPlaces @child-rendered="childRendered()"
                /></v-tab-item>
                <v-tab-item v-if="loaded && tab === 'System'" key="System" value="System">
                    <JobAdPubPlacesSystemPlaces @child-rendered="childRendered()"
                /></v-tab-item>
                <v-tab-item v-if="loaded && tab === 'User'" key="User" value="User">
                    <JobAdPubPlacesUserPlaces @child-rendered="childRendered()" />
                </v-tab-item>
            </v-tabs-items>
        </validation-observer>
    </v-container>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import ColoredStatus from '@/components/ColoredStatus'
import { mapFields } from 'vuex-map-fields'
import { mapActions } from 'vuex'
import { router } from '@/router'

export default {
    components: {
        ColoredStatus,
        ValidationObserver,
    },
    data: () => ({
        tab: null,
        loaded: false,
    }),
    computed: {
        ...mapFields('pub', [
            'currentItem.id',
            'currentItem.statusActive',
            'currentItem.statusText',
            'currentItem.placeId',
            'currentItem.publishedFrom',
            'currentItem.publishedTo',
            'currentItem.remarksToPublisher',
            'currentItem.isActive',
            'currentItem.link',
            'currentItem.applyLink',
            'currentItem.createdBy',
            'currentItem.createdAt',
        ]),
    },
    mounted() {
        if (window.parentIFrame) {
            window.parentIFrame.scrollTo(0, 0)
        }

        this.getItemById(this.$route.params.pub).then(() => {
            this.getItemExtensions().then(() => {
                switch (true) {
                    case this.placeId <= -10000 || this.placeId == null:
                        this.tab = 'Pms'
                        break
                    case this.placeId < 0 && this.placeId > -10000:
                        this.tab = 'System'
                        break
                    default:
                        this.tab = 'User'
                        break
                }
                this.loaded = true
                this.$emit('child-rendered')
            })
        })
    },
    methods: {
        ...mapActions('pub', ['getItemById', 'editItem', 'resetItem', 'getItemExtensions']),

        childRendered() {
            if (this.$refs.observer) {
                this.$refs.observer.validate()
            }
        },

        close() {
            this.resetItem()
            router.push({ name: 'ad' })
        },

        save() {
            this.editItem()
            router.push({ name: 'ad' })
        },
    },
}
</script>

<style scoped>
.v-tab {
    color: #777777;
    background-color: #efefef;

    sup {
        color: #cd1d1d;
    }

    border-right: 6px solid white;
}
.v-tab--active {
    color: #efefef;
    background-color: #777777;

    sup {
        color: #efefef;
    }
}
.v-toolbar__content {
    padding-left: 0 !important;
    padding-right: 0 !important;
}
</style>
