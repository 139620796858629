<template>
    <v-card outlined>
        <v-card-text>
            <v-row>
                <v-col cols="12">
                    <v-row>
                        <v-col cols="12">
                            <validation-provider
                                v-slot="{ errors }"
                                :name="$t('oampublication/label--place-text')"
                                rules="required"
                            >
                                <v-slide-group
                                    v-model="selected"
                                    prev-icon="mdi-chevron-double-left"
                                    next-icon="mdi-chevron-double-right"
                                    mandatory
                                    center-active
                                    show-arrows
                                    :error-messages="errors"
                                    @change="pmsPlaceChanged"
                                >
                                    <v-slide-item
                                        v-for="(pmsPlace, index) in pmsPlaces"
                                        :key="index"
                                        v-slot="{ active, toggle }"
                                    >
                                        <v-card
                                            :style="active ? 'border: 3px solid #3b5998 !important' : ''"
                                            :color="active ? 'grey lighten-2' : 'grey lighten-4'"
                                            class="ma-2"
                                            height="540"
                                            width="300"
                                            @click="toggle"
                                        >
                                            <v-card-title class="grey darken-3">
                                                <v-row>
                                                    <v-col class="white--text"
                                                        >{{ pmsPlace.mediaName }}<br />
                                                        <span class="text-subtitle-1">{{ pmsPlace.mediaType }}</span
                                                        ><span
                                                            v-if="pmsPlace.mediaType === 'Einzelinserat'"
                                                            class="text-subtitle-1"
                                                        >
                                                            - {{ pmsPlace.durationDays }} Tage</span
                                                        ></v-col
                                                    >
                                                    <v-col
                                                        v-if="pmsPlace.discountPercent > 0"
                                                        cols="4"
                                                        class="text-right"
                                                        ><v-btn fab dark large color="warning" class="black--text">
                                                            {{ `-${Math.round(pmsPlace.discountPercent)}%` }}
                                                        </v-btn></v-col
                                                    >
                                                </v-row>
                                            </v-card-title>
                                            <v-card-text class="pa-4"
                                                ><span v-for="(line, i) in pmsPlace.descriptionDE.split('\n')" :key="i">
                                                    {{ line }}<br />
                                                </span>
                                            </v-card-text>

                                            <v-footer class="counter">
                                                <v-col class="text-right pa-0">{{
                                                    `${index + 1}/${pmsPlaces.length}`
                                                }}</v-col>
                                            </v-footer>

                                            <v-footer>
                                                <v-col class="text-left px-0"
                                                    ><span
                                                        v-if="pmsPlace.discountPercent > 0"
                                                        class="text-h6 text-decoration-line-through"
                                                        >{{
                                                            `${pmsPlace.currency} ${pmsPlace.priceWithoutDiscount}`
                                                        }}</span
                                                    >
                                                </v-col>
                                                <v-col class="text-right px-0"
                                                    ><span class="text-h6">
                                                        {{ `${pmsPlace.currency} ${pmsPlace.price}` }}</span
                                                    >
                                                </v-col>
                                            </v-footer>
                                        </v-card>
                                    </v-slide-item>
                                </v-slide-group>
                            </validation-provider>
                        </v-col>
                        <v-col cols="12">
                            <v-textarea
                                v-model="remarksToPublisher"
                                :label="$t('oampublication/label--remarks-to-publisher')"
                                :hint="$t('oampublication/label--remarks-to-publisher-hint')"
                                counter
                                maxlength="1000"
                            ></v-textarea>
                        </v-col>
                        <v-col cols="6">
                            <v-menu v-model="dateMenu" :close-on-content-click="false" max-width="290">
                                <template #activator="{ on, attrs }">
                                    <validation-provider
                                        v-slot="{ errors }"
                                        vid="publishedFrom"
                                        rules="required|lt:@publishedTo"
                                        :name="$t('oampublication/label--published-from')"
                                    >
                                        <v-text-field
                                            :error-messages="errors"
                                            :value="compPublishedFrom"
                                            :label="$t('oampublication/label--published-from')"
                                            clearable
                                            v-bind="attrs"
                                            readonly
                                            v-on="on"
                                            @click:clear="publishedFrom = null"
                                        ></v-text-field>
                                    </validation-provider>
                                </template>
                                <v-date-picker
                                    v-model="publishedFrom"
                                    no-title
                                    color="primary"
                                    @input="dateMenu = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="6">
                            <v-menu v-model="dateMenu2" :close-on-content-click="false" max-width="290">
                                <template #activator="{ on, attrs }">
                                    <validation-provider
                                        v-slot="{ errors }"
                                        vid="publishedTo"
                                        rules="gt:@publishedFrom"
                                    >
                                        <v-text-field
                                            :error-messages="errors"
                                            :value="compPublishedTo"
                                            :label="$t('oampublication/label--published-to')"
                                            clearable
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            @click:clear="publishedTo = null"
                                        ></v-text-field>
                                    </validation-provider>
                                </template>
                                <v-date-picker
                                    v-model="publishedTo"
                                    no-title
                                    color="primary"
                                    @input="dateMenu2 = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
            <v-col class="text--secondary caption">
                {{ $t('oampublication/label--created-at') }} {{ compCreatedAt }}
                {{ $t('oampublication/label--created-by') }} {{ createdBy }}
            </v-col>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import moment from 'moment'
import { ValidationProvider } from 'vee-validate'

export default {
    components: {
        ValidationProvider,
    },
    data: () => ({
        dateMenu: false,
        dateMenu2: false,
    }),
    computed: {
        ...mapGetters('place', ['pmsPlaces']),
        ...mapFields('pub', [
            'currentItem.id',
            'currentItem.statusActive',
            'currentItem.statusText',
            'currentItem.placeId',
            'currentItem.publishedFrom',
            'currentItem.publishedTo',
            'currentItem.remarksToPublisher',
            'currentItem.isActive',
            'currentItem.link',
            'currentItem.applyLink',
            'currentItem.createdBy',
            'currentItem.createdAt',
        ]),
        selected: {
            get: function () {
                return this.pmsPlaces.findIndex(({ id }) => id === this.placeId)
            },
            set: function (val) {
                this.placeId = this.pmsPlaces[val].id
            },
        },
        compCreatedAt() {
            return this.createdAt ? moment(this.createdAt).format('DD.MM.YYYY HH:mm') : ''
        },
        compPublishedFrom() {
            return this.publishedFrom ? moment(this.publishedFrom).format('DD.MM.YYYY') : ''
        },
        compPublishedTo() {
            return this.publishedTo ? moment(this.publishedTo).format('DD.MM.YYYY') : ''
        },
    },
    mounted() {
        this.getPlaces(this.$route.params.pub).then(() => {
            this.$emit('child-rendered')
        })
    },
    methods: {
        ...mapActions('place', { getPlaces: 'getPmsItems' }),
        ...mapActions('alert', ['showSuccess']),

        pmsPlaceChanged(index) {
            const pmsPlace = this.pmsPlaces[index]
            const duration = pmsPlace ? pmsPlace.durationDays : -1

            if (moment().day() === 5 || moment().day() === 6) {
                // friday or saturday -> show monday
                this.publishedFrom = moment().day(8).toISOString()
            } else {
                // other days, show next day
                this.publishedFrom = moment().add(1, 'days').toISOString()
            }
            this.publishedTo = moment(this.publishedFrom).add(duration, 'days').toDate().toISOString()
        },
    },
}
</script>

<style scoped>
.places-toolbar >>> .v-toolbar__content {
    padding: 0px !important;
}
.v-footer {
    color: #efefef;
    background-color: #999999;
}
html {
    overflow: hidden !important;
}
.v-card {
    display: flex !important;
    flex-direction: column;
}
.v-card__text {
    flex-grow: 1;
    overflow: auto;
}
.counter {
    font-size: smaller;
    color: rgba(0, 0, 0, 0.6);
    background-color: transparent;
}
</style>
